export class FetchHandler {

    constructor() {
        this.url = undefined;
        this.method = 'POST';
        this.contentType = 'application/x-www-form-urlencoded';

        this.headers = {};
        this.body = {};

        this.fetchingInProgress = false;
        this._abortController = new AbortController();
    }

    /**
     * The constructor is private, so the only way to create an instance is through the create method
     */
    static create() {
        return new FetchHandler();
    }

    /**
     * Set the url of the request
     * @param url
     */
    setUrl(url) {
        this.url = url;
        return this;
    }

    /**
     * Set the method of the request
     * This is optional, the default value is 'POST'
     * @param method
     */
    setMethod(method) {
        this.method = this.checkMethod(method) ? method : this.method;
        return this;
    }

    /**
     * Set a header for the request
     * This is optional, the default value is an empty object
     * @param key
     * @param value
     */
    setHeader(key, value ) {
        this.headers[key] = value;
        return this;
    }

    /**
     * Set the content type of the request
     * This is optional, the default value is 'application/json'
     * @param contentType
     */
    setContentType(contentType) {
        this.contentType = this.checkContentType(contentType) ? contentType : this.contentType;

        return this;
    }

    /**
     * Set the body of the request
     * This is optional, the default value is an empty object
     * @param body
     */
    setBody(body) {
        this.body = body;
        return this;
    }

    abort() {
        this._abortController.abort();
        return this;
    }

    isFetching() {
        return this.fetchingInProgress;
    }

    setXMLHttpRequest() {
        this.headers = {
            ...this.headers,
            'X-Requested-With': 'XMLHttpRequest'
        }
        return this;
    }

    /**
     * Send the request to the server
     * @returns {Promise<any>}
     */
    async send() {
        this.initCSRFToken();
        this.initContentType();

        const options = {
            method: this.method,
            headers: this.headers,
            body: this.body,
            signal: this._abortController.signal
        };

        try {
            this.fetchingInProgress = true;
            const _response = await fetch(this.url, options);
            this.fetchingInProgress = false;

            const _jsonResponse = _response.json();
            if (_response.status >= 200 && _response.status < 300) {
                return _jsonResponse;
            }

            throw await _jsonResponse;
        } catch (error) {
            this.fetchingInProgress = false;

            if (error instanceof Error) {
                throw {
                    errors: {
                        network: 'Network error'
                    }
                }
            }
            throw error;
        }
    }

    /**
     * Initialize the CSRF token
     * Internal method, should not be called from outside
     * @private
     */
    initCSRFToken() {
        const token = document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

        this.headers = {
            ...this.headers,
            'X-CSRF-TOKEN': token ?? ''
        }
    }

    /**
     * Initialize the content type
     * Internal method, should not be called from outside
     * @private
     */
    initContentType() {
        this.setHeader('Content-Type', this.contentType + ';charset=UTF-8');
    }

    /**
     * Check if the given method type is correct
     * @param method
     * @return {boolean}
     */
    checkMethod(method) {
        return ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].includes(method);
    }

    /**
     * Check if the given mimeType is correct
     * @param contentType
     * @return {boolean}
     */
    checkContentType(contentType) {
        return ['application/json', 'text/json', 'application/x-www-form-urlencoded'].includes(contentType);
    }
}
